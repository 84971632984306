// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

let slideIndex = 0

const setupGallery = () => {
  let galleries = document.getElementsByClassName('gallery')
  let galleryModal = document.getElementById('gallery-modal')
  let closeGalleryModal = document.getElementById('gallery-modal-close')
  let prevPhoto = document.getElementById('prev-photo')
  let nextPhoto = document.getElementById('next-photo')

  if (!galleryModal) return

  // Set up gallery listeners
  for (var i = 0 ; i < galleries.length ; i++) {
    var photos = galleries[i].getElementsByClassName('flower')

    for (var j = 0 ; j < photos.length ; j++) {
      photos[j].addEventListener('click', (e) => {
        let smallUrls = e.target.dataset['smallImages'].split('|')
        let largeUrls = e.target.dataset['largeImages'].split('|')
        let caption = e.target.dataset['caption']

        if (largeUrls.length > 1) {
          prevPhoto.style.display = 'block'
          nextPhoto.style.display = 'block'
        } else {
          prevPhoto.style.display = 'none'
          nextPhoto.style.display = 'none'
        }
        renderPhotos(galleryModal, smallUrls, largeUrls, caption)
        showSlide(galleryModal, slideIndex = 0)
        galleryModal.style.display = 'block'
      })
    }
  }

  // Set up close button
  closeGalleryModal.addEventListener('click', () => {
    closeModal(galleryModal)
  })

  // Set up prev and next buttons
  prevPhoto.addEventListener('click', () => {
    showSlide(galleryModal, slideIndex -= 1)
  })

  nextPhoto.addEventListener('click', () => {
    showSlide(galleryModal, slideIndex += 1)
  })

  // Set up hot keys
  document.addEventListener('keyup', (e) => {
    if (galleryModal.style.display != 'block') return

    switch (e.keyCode) {
      case 39: // Right arrow
        showSlide(galleryModal, slideIndex += 1)
        return
      case 37: // Left arrow
        showSlide(galleryModal, slideIndex -= 1)
        return
      case 27: // Escape
        closeModal(galleryModal)
        return
    }
  })

  // Set up swipes
  let touchstartX = 0
  let touchendX = 0

  galleryModal.addEventListener('touchstart', function(event) {
    touchstartX = event.changedTouches[0].screenX
  }, false)

  galleryModal.addEventListener('touchend', function(event) {
    touchendX = event.changedTouches[0].screenX

    if (touchendX < touchstartX) {
      showSlide(galleryModal, slideIndex += 1)
    }

    if (touchendX > touchstartX) {
      showSlide(galleryModal, slideIndex -= 1)
    }
  }, false)
}

const renderPhotos = (galleryModal, smallImages, largeImages, caption) => {
  let largeImagesContainer = galleryModal.getElementsByClassName('images')[0]
  let thumbnailsContainer = galleryModal.getElementsByClassName('thumbnails')[0]
  let imagesHtml = ''
  let thumbnailsHtml = ''

  // Add large images
  for (var i = 0 ; i < largeImages.length ; i++) {
    imagesHtml += `
      <div class="image">
        <div class="numbertext">${i+1} / ${largeImages.length}</div>
        <img src="${largeImages[i]}" alt="${caption}">
      </div>
    `
  }
  largeImagesContainer.innerHTML = imagesHtml

  // Add thumbnail images
  for (var i = 0 ; i < smallImages.length ; i++) {
    thumbnailsHtml += `<img class="thumbnail" src="${smallImages[i]}" alt="${caption}">`
  }
  thumbnailsContainer.innerHTML = thumbnailsHtml

  // Bind click events for thumbnails
  let thumbnails = thumbnailsContainer.getElementsByClassName('thumbnail')
  for (var i = 0 ; i < thumbnails.length ; i++) {
    let j = i
    thumbnails[i].addEventListener('click', () => {
      showSlide(galleryModal, slideIndex = j)
    })
  }
}

const showSlide = (galleryModal, slideNum) => {
  var slides = galleryModal.getElementsByClassName('image');
  var dots = galleryModal.getElementsByClassName('thumbnail');
  var captionText = document.getElementById('photo-caption');
  if (slideNum >= slides.length) {slideIndex = 0}
  if (slideNum < 0) {slideIndex = slides.length - 1}

  for (var i = 0; i < slides.length; i++) {
    slides[i].style.display = 'none';
  }

  for (var i = 0; i < dots.length; i++) {
    dots[i].className = dots[i].className.replace(' active', '')
  }

  slides[slideIndex].style.display = 'block';
  dots[slideIndex].className += ' active';
  captionText.innerHTML = dots[slideIndex].alt;
}

const closeModal = (galleryModal) => {
  galleryModal.style.display = 'none'

  // Unbind thumbnail click events
  // let thumbnails = galleryModal.getElementsByClassName('thumbnail')
  // for (var i = 0 ; i < thumbnails.length ; i++) {
  //   thumbnails[i].removeEventListener('click', () => {})
  // }
}

const domReady = () => {
  setupGallery()
}

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
  domReady()
} else {
  document.addEventListener("DOMContentLoaded", domReady)
}
